import API, {GraphQLResult, graphqlOperation} from '@aws-amplify/api';
import _ from 'lodash';
import {useCallback, useState} from 'react';
import {createContainer} from 'unstated-next';
import {SaaSCityPoiByPrefCodeQuery} from '../../../../API';
import {saaSCityPoiByPrefCode} from '../../../../graphql/queries';
import {regions} from './def';

type Region = {
  id: string;
  name: string;
  prefs: {
    prefCode: string;
    prefName: string;
  }[];
};

type Pref = {
  id?: string;
  name?: string;
  prefCode: string;
  prefName: string;
  cities?: Array<{
    id: string;
    cityName: string;
    prefCode: string;
    prefName: string;
  }>;
};

type City = {
  id: string;
  cityName: string;
  prefCode: string;
  prefName: string;
};

type Result = {
  region: Region[];
  pref: Pref[];
  city: City[];
};

const usePrefecture = () => {
  const [result, setResult] = useState<Result>({
    region: [],
    pref: [],
    city: [],
  });

  const [isGetLoading, setGetLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | undefined>();
  const [cities, setCities] = useState<any>([]);

  const [selectedRegionList, setSelectedRegionList] = useState<Region[]>([]);
  const addSelectedRegion = useCallback(
    (value: Region) => {
      setSelectedRegionList([...selectedRegionList, value]);
    },
    [selectedRegionList, setSelectedRegionList],
  );
  const removeSelectedRegion = useCallback(
    (value: Region) => {
      const newTargets = selectedRegionList.filter(
        (selected) => selected.id !== value.id,
      );
      setSelectedRegionList(newTargets);
    },
    [selectedRegionList, setSelectedRegionList],
  );
  const [selectedPrefList, setSelectedPrefList] = useState<Array<Pref>>([]);
  const addSelectedPref = useCallback(
    async (value: Pref) => {
      setSelectedPrefList([...selectedPrefList, value]);
    },
    [selectedPrefList, setSelectedPrefList],
  );
  const addSelectedPrefs = useCallback(
    (values: any) => {
      setSelectedPrefList([...selectedPrefList, ...values]);
    },
    [selectedPrefList, setSelectedPrefList],
  );
  const removeSelectedPref = useCallback(
    async (value: Pref) => {
      const newTargets = selectedPrefList.filter(
        (selected) => selected.prefCode !== value.prefCode,
      );
      setSelectedPrefList(newTargets);
    },
    [selectedPrefList, setSelectedPrefList],
  );
  const removeSelectedPrefs = useCallback(
    (values: Array<Pref>) => {
      const newTargets: Array<Pref> = [];
      selectedPrefList.forEach((selected) => {
        if (!values.some((value) => value.prefCode === selected.prefCode)) {
          newTargets.push(selected);
        }
      });
      setSelectedPrefList(newTargets);
    },
    [selectedPrefList, setSelectedPrefList],
  );
  const [selectedCityList, setSelectedCityList] = useState<Array<City>>([]);
  const addSelectedCity = useCallback(
    (value: City) => {
      setSelectedCityList([...selectedCityList, value]);
    },
    [selectedCityList, setSelectedCityList],
  );
  const addSelectedCities = useCallback(
    (values: any) => {
      setSelectedCityList([...selectedCityList, ...values]);
    },
    [selectedCityList, setSelectedCityList],
  );
  const removeSelectedCity = useCallback(
    (value: City) => {
      const newTargets = selectedCityList.filter(
        (selected) => selected.id !== value.id,
      );
      setSelectedCityList(newTargets);
    },
    [selectedCityList, setSelectedCityList],
  );
  const removeSelectedCities = useCallback(
    (values: Array<City>) => {
      const newTargets: Array<City> = [];
      selectedCityList.forEach((selected) => {
        if (!values.some((value) => value.id === selected.id)) {
          newTargets.push(selected);
        }
      });
      setSelectedCityList(newTargets);
    },
    [selectedCityList, setSelectedCityList],
  );
  const [expand, setExpand] = useState<{
    region?: Region;
    prefecture?: Pref | undefined;
  }>({});
  const expandItem = useCallback(
    ({region, prefecture}: {region: Region; prefecture: Pref | undefined}) => {
      if (prefecture) {
        expand.region === region && expand.prefecture === prefecture
          ? setExpand({region, prefecture: undefined})
          : setExpand({region, prefecture});
      } else {
        expand.region === region
          ? setExpand({})
          : setExpand({region, prefecture: undefined});
      }
    },
    [expand],
  );

  const fetchCities = useCallback(async (prefCode: string) => {
    setGetLoading(true);
    setError(undefined);
    try {
      const result = (await API.graphql(
        graphqlOperation(saaSCityPoiByPrefCode, {prefCode}),
      )) as GraphQLResult<SaaSCityPoiByPrefCodeQuery>;
      if (!result.data) {
        throw new Error('[getPaymentInfo] result.data is undefined');
      }
      setCities(result.data.saaSCityPoiByPrefCode);
      return result.data.saaSCityPoiByPrefCode;
    } catch (err: any) {
      setError(err);
      throw err;
    } finally {
      setGetLoading(false);
    }
  }, []);

  const commit = useCallback(() => {
    setResult({
      region: selectedRegionList,
      pref: selectedPrefList,
      city: selectedCityList,
    });
  }, [selectedRegionList, selectedPrefList, selectedCityList]);

  const restoreByTarget = useCallback(
    async (
      values: Array<{
        cityCode?: string;
        cityName?: string;
        prefCode: string;
        prefName: string;
      }>,
    ) => {
      const restoreRegions: Array<Region> = [];
      let restorePrefs: Array<Pref> = [];
      const restoreCities: Array<City> = [];
      values.forEach((value) => {
        const {prefCode, cityCode, prefName, cityName} = value;
        if (cityCode && cityName) {
          restoreCities.push({
            prefCode,
            id: cityCode,
            prefName,
            cityName,
          });
        } else {
          const restoreRegion = regions.filter((region) =>
            region.prefs.some((pref) => pref.prefCode === prefCode),
          )[0];
          restorePrefs.push({
            id: restoreRegion.id,
            name: restoreRegion.name,
            prefCode,
            prefName,
          });
        }
      });
      const sortedPrefList = _.sortBy(restorePrefs, 'prefCode');
      regions.forEach((region) => {
        const sortedDefPrefList = _.sortBy(region.prefs, 'prefCode').map(
          (item) => item.prefCode,
        );
        const isEqual =
          JSON.stringify(
            sortedPrefList
              .filter((pref) => pref.id === region.id)
              .map((item) => item.prefCode),
          ) === JSON.stringify(sortedDefPrefList);
        if (isEqual && region.id !== '0' && region.id !== '8') {
          restoreRegions.push({
            id: region.id,
            name: region.name,
            prefs: sortedPrefList.filter((pref) => pref.id === region.id),
          });
          restorePrefs = restorePrefs.filter((pref) => pref.id !== region.id);
        }
      });
      setSelectedRegionList(restoreRegions);
      setSelectedPrefList(restorePrefs);
      setSelectedCityList(restoreCities);
      setResult({
        region: restoreRegions,
        pref: restorePrefs,
        city: restoreCities,
      });
    },
    [],
  );

  const clearAll = useCallback(() => {
    setSelectedRegionList([]);
    setSelectedPrefList([]);
    setSelectedCityList([]);
    setResult({region: [], pref: [], city: []});
    setExpand({});
  }, []);

  const clearExpand = useCallback(() => {
    setExpand({});
  }, []);

  const canConfirm =
    selectedRegionList.length !== 0 ||
    selectedPrefList.length !== 0 ||
    selectedCityList.length !== 0;

  const restore = useCallback(() => {
    setSelectedRegionList(result.region);
    setSelectedPrefList(result.pref);
    setSelectedCityList(result.city);
  }, [result.region, result.pref, result.city]);

  const cancel = useCallback(() => {
    restore();
  }, [restore]);

  return {
    expand,
    expandItem,
    selectedRegionList,
    addSelectedRegion,
    removeSelectedRegion,
    selectedPrefList,
    addSelectedPref,
    removeSelectedPref,
    addSelectedPrefs,
    removeSelectedPrefs,
    selectedCityList,
    addSelectedCity,
    addSelectedCities,
    removeSelectedCity,
    removeSelectedCities,
    isGetLoading,
    error,
    cities,
    fetchCities,
    restoreByTarget,
    clearAll,
    clearExpand,
    canConfirm,

    commitPrefecture: commit,
    cancelPrefecture: cancel,
    restorePrefecture: restore,
    clearPrefecture: clearAll,
  };
};

export const PrefectureContainer = createContainer(usePrefecture);
